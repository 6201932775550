import { render, staticRenderFns } from "./AdsSbcProcPurchaseStockSale1dList.vue?vue&type=template&id=4f067854&scoped=true&"
import script from "./AdsSbcProcPurchaseStockSale1dList.vue?vue&type=script&lang=js&"
export * from "./AdsSbcProcPurchaseStockSale1dList.vue?vue&type=script&lang=js&"
import style0 from "./AdsSbcProcPurchaseStockSale1dList.vue?vue&type=style&index=0&id=4f067854&prod&lang=scss&scoped=true&"
import style1 from "./AdsSbcProcPurchaseStockSale1dList.vue?vue&type=style&index=1&id=4f067854&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f067854",
  null
  
)

export default component.exports