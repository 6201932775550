import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function userUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'user_id', label: 'ID', sortable: true },
    // { key: 'company_id', label: '公司ID', sortable: true },
    // { key: 'deptartment_id', label: '部门ID', sortable: true },
    // { key: 'username', label: '登录名', sortable: true },
    // { key: 'password', label: '密码', sortable: true },
    { key: 'full_name', label: '真实姓名', sortable: true },
    // { key: 'avatar', label: '头像', sortable: true },
    { key: 'mobile', label: '手机号', sortable: true },
    { key: 'email', label: '邮件', sortable: true },
    { key: 'job_num', label: '工号', sortable: true },
    // { key: 'role_id', label: '角色ID', sortable: true },
    // { key: 'level', label: '职位', sortable: true },
    // { key: 'login_time', label: '登录时间', sortable: true },
    // { key: 'login_num', label: '登录次数', sortable: true },
    // { key: 'ip_address', label: 'IP', sortable: true },
    // { key: 'status', label: '状态', sortable: true },
    // { key: 'remark', label: '', sortable: true },
    // { key: 'state', label: '启用', sortable: true },
    // { key: 'create_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人', sortable: true },
    //     { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const companyIdRelational = ref(0)
  const searchKey = ref('')
  const orderBy = ref('user_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('user/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        company_id: companyIdRelational.value,
        user_department:config.userDepartment,
        departmentId:config.departmentId,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '用户列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    companyIdRelational,

    refetchData,

    // Extra Filters
  }
}
