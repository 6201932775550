import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCodeLabel } from '@core/utils/filter'

export default function purchaseorderwatcherUseList(state,config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    /*{
      key: 'id',
      label: 'ID'
    },*/
    /*{
      key: 'order_id',
      label: '采购单ID'
    },*/
    {
      key: 'user_id',
      label: '观察员',
      formatter: value => getCodeLabel('user',value)
    },
    /*{
      key: 'state',
      label: '状态'
    },
    {
      key: 'create_time',
      label: '添加时间'
    },
    {
      key: 'creator',
      label: '添加人ID'
    },
    {
      key: 'update_time',
      label: '更新时间'
    },
    {
      key: 'updator',
      label: '更新人ID'
    },*/
    {
      key: 'actions',
      label: '操作'
    },
  ]

  const refresh = () => {
    refListTable.value.refresh()
  }

  const serverItems = ref([])

  const getList = async () => {
    const res = await store.dispatch('purchaseorderwatcher/list',{orderId:config.orderId})
    if (res['data'].code === 0){
      serverItems.value = res['data'].data.ext.list
      state.items = res['data'].data.ext.list
    }else{
      toast.error("采购单观察员列表获取错误")
    }
  }

  onMounted( async () => {
     await getList()
  })

  return {
    serverItems,
    tableColumns,
    refListTable,
    refresh,
  }
}
