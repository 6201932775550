<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="未找到记录"
      >
        <template #cell(id)="data">
          {{ data.item.count_num }}
        </template>

        <template #cell(cgsm)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{  data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
            <feather-icon  icon="EditIcon" @click="showRemark(data.item)"/>
          </div>
          <b-tooltip
                  :target="`remark-row-${data.item.id}`"
                  placement="top"
                  v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

      </b-table>
    </b-card>

    <b-modal
            id="modal-remark"
            ok-only
            ok-title="确认"
            @ok="saveRemark"
            cancel-title="取消"
            centered
            size="lg"
            title="修改采购说明"
            ref="remarkModal"
    >
      <b-card>
        <b-form-textarea
                id="textarea"
                v-model="remarkInfo"
                rows="3"
                max-rows="6"
        />
      </b-card>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import adssbcprocpurchasestocksale1dUseList from './adssbcprocpurchasestocksale1dUseList'
import purchaseorderStore from '@/views/apps/purchaseorder/purchaseorderStore'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      selectedItem: {},
      remarkInfo:'',
    }
  },
  methods: {
    showRemark(item) {
      this.selectedItem = item
      this.remarkInfo = item.cgsm
      this.$refs['remarkModal'].show()
    },
  },
  props: ['order_id'],
  setup(props) {
    if (!store.hasModule('purchaseorder')) store.registerModule('purchaseorder', purchaseorderStore)
    const toast = useToast()
    const saveRemark = function () {
      store.dispatch('purchaseorder/saveRemark', {id:this.selectedItem.ads_id,remark:this.remarkInfo})
              .then(res => {
                if (res.data.code === 0) {
                  toast.success('修改成功')
                  refetchData()
                } else {
                  toast.error('修改失败')
                }
              })
    }

    const {
      searchList,
      tableColumns,
      refetchData,
      refListTable
      // UI
    } = adssbcprocpurchasestocksale1dUseList({},props)

    return {
      saveRemark,
      searchList,
      tableColumns,
      refetchData,
      refListTable
      // Filter
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
