<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
          >
            <b-alert
                variant="primary"
                show

            >
              <div class="alert-body">
                新增未保存
              </div>
            </b-alert>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  class="mr-1"
                  @click="showUserModal"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
              <b-button
                  variant="primary"
                  @click="save"
              >
                <span class="text-nowrap">保存</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="items"
          responsive
          hover
          :fields="tableColumns"
          primary-key="user_id"
          show-empty
          empty-text="未找到记录"
          :tbody-tr-class="rowClass"
      >
        <template #cell(actions)="data">
          <b-link>
            <feather-icon
                @click="del(data)"
                icon="XSquareIcon"
                size="18"
            />
          </b-link>
        </template>

      </b-table>
    </b-card>

    <b-modal
        id="modal-user"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        title="请选择观察员"
        ref="userModal"
    >
      <user-select
          ref="watcherSelect" v-if="loaded" :department-id="1"
      >
      </user-select>
    </b-modal>

  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted, reactive, toRefs, computed, } from '@vue/composition-api'
import { getCodeLabel, getCodeOptions } from '@core/utils/filter'
import purchaseorderwatcherUseList from './purchaseorderwatcherUseList'
import purchaseorderwatcherStore from './purchaseorderwatcherStore'
import UserSelect from '@/views/apps/user/user-select/UserSelect'
import { useToast } from 'vue-toastification/composition'
import { getUserData } from '@/auth/utils'

export default {
  components: { UserSelect },
  props: ['orderId'],
  setup(props, { emit }) {
    // Register module
    if (!store.hasModule('purchaseorderwatcher')) store.registerModule('purchaseorderwatcher', purchaseorderwatcherStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseorderwatcher')) store.unregisterModule('purchaseorderwatcher')
    })

    const toast = useToast()

    const user = getUserData()
    const state = reactive({
      items: [],

      loaded: false,
      userModal: null,
      watcherSelect: null,
    })

    const {
      serverItems,
      tableColumns,
      refListTable,
      refresh,

    } = purchaseorderwatcherUseList(state, {
      orderId: props.orderId
    })

    const unsavedItems = computed(() => state.items.filter(item => item.color))

    const methods = {
      onSelectSingle: function () {
        const selectedItems = state.watcherSelect.selected
        const unsavedItems = selectedItems.map(item => {
          return {
            user_id: item.id,
            order_id: props.orderId,
            color: 'table-primary'
          }
        })
            .filter(item => item.user_id !== user.user_id)
        const items = unsavedItems.concat(state.items)
        const obj = {}
        for (const item of items) {
          obj['_' + item.user_id] = item
        }
        state.items = Object.values(obj)
        state.loaded = false
      },
      showUserModal() {
        state.userModal.show()
        state.loaded = true
      },
      async del(data) {
        const item = data.item
        const index = data.index
        if (item.color) {
          state.items.splice(index, 1)
          toast.success('删除成功')
          refresh()
        } else {
          const res = await store.dispatch('purchaseorderwatcher/state', {
            id: item.id,
            state: 0
          })
          if (res.data.code === 0) {
            state.items.splice(index, 1)
            toast.success('删除成功')
            refresh()
          } else {
            toast.error('删除失败')
          }
        }
      },
      save() {
        if (!unsavedItems.value.length) {
          toast.error('没有需要保存的内容，直接关闭即可')
          return
        }
        store.dispatch('purchaseorderwatcher/save', {
          watchers: unsavedItems.value
        })
            .then(res => {
              if (res.data.code === 0) {
                toast.success(res.data.data)
                emit('hideModal')
              } else {
                toast.error(res.data.data)
              }

            })
      },
      rowClass(item, type) {
        if (!item || type !== 'row') {
          return
        }
        return item.color
      },
    }

    return {
      ...toRefs(state),
      ...methods,

      tableColumns,
      refListTable,
      refresh,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
