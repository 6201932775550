<template>
  <b-card
      style="width:100%"
  >
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >
      <b-row>

        <!--入库单编号-->
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="入库单编号:"
              label-for="inboundNo"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.inboundNo"
                name="inboundNo"
                class="d-inline-block mr-1"
                size="sm"
            />
          </b-form-group>
        </b-col>
        <!--采购单编号-->
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="采购单编号:"
              label-for="orderNo"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.orderNo"
                name="orderNo"
                class="d-inline-block mr-1"
                size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品:"
              label-for="productCondition"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.productCondition"
                name="productCondition"
                class="d-inline-block mr-1"
                size="sm"
                placeholder="请输入69码或名称"
            />
          </b-form-group>
        </b-col>
        <!--仓库名称-->
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="仓库名称:"
              label-for="warehouse_name"
              label-size="sm"
          >
            <b-form-input
                id="warehouse_name"
                size="sm"
                v-model="condition.warehouseName"
                name="warehouseName"
                @click="showSingleModal('仓库名称')"
                readonly
                placeholder="点击搜索仓库名称"
            />
          </b-form-group>
        </b-col>
        <!--入库状态-->
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="入库状态:"
              label-for="status"
              label-size="sm"
          >
            <v-select
                id="status"
                :options="getCodeOptions('purchase_inbound_status')"
                v-model="condition.status"
                name="status"
                class="select-size-sm"
                placeholder="请选择入库状态"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <!--采购确认-->
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="采购确认:"
              label-for="statusSales"
              label-size="sm"
          >
            <v-select
                id="statusSales"
                :options="getCodeOptions('purchase_inbound_status_sales')"
                v-model="condition.status_sales"
                name="statusSales"
                class="select-size-sm"
                placeholder="请选择采购确认状态"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="3"
            v-if="isExpressStaff || isSuper"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="物流审核:"
              label-for="expressCheckStatus"
              label-size="sm"
          >
            <v-select
                id="expressCheckStatus"
                :options="getCodeOptions('yesno')"
                v-model="condition.express_check_status"
                name="statusSales"
                class="select-size-sm"
                placeholder="请选择物流审核"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="创建人:"
              label-for="creator"
              label-size="sm"
          >
            <b-form-input
                id="creator"
                size="sm"
                v-model="condition.creatorName"
                @click="showSingleModal('创建人')"
                readonly
                placeholder="点击搜索创建人"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="入库人:"
              label-for="inbound_creator"
              label-size="sm"
          >
            <b-form-input
                id="inbound_creator"
                size="sm"
                v-model="condition.inboundCreatorName"
                @click="showSingleModal('入库人')"
                readonly
                placeholder="点击搜索入库人"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="入库时间:"
              label-size="sm"
          >
            <flat-pickr
                v-model="condition.inbound_time"
                class="form-control"
                :config="rangeConfig"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="供应商:"
              label-for="supplier_id"
              label-size="sm"
          >
            <b-form-input
                id="supplier_id"
                size="sm"
                v-model="condition.supplierName"
                name="supplierId"
                @click="showSingleModal('供应商')"
                readonly
                placeholder="点击搜索供应商"
            />
          </b-form-group>
        </b-col>
        <!--查询  重置-->
        <b-col
            cols="12"
            md="3"
        >
          <b-button
              variant="outline-primary"
              type="submit"
              class="mr-1"
          >
            <span class="align-right">查询</span>
          </b-button>

          <b-button
              variant="outline-primary"
              type="reset"
          >
            <span class="align-right">重置</span>
          </b-button>

        </b-col>
      </b-row>
    </b-form>

    <!--弹出框头部和尾部-->
    <b-modal
        id="modal-select-warehouse"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <company-select
          ref="companySelect" :companyType="companyType" v-if="modalName === '签约主体' || modalName === '供应商' "
      />
      <!--仓库名称-->
      <warehouse-list
          ref="warehouseSelect" :warehouse-id="warehouseId" v-if="'仓库名称' === modalName "
      >

      </warehouse-list>
      <user-select
          ref="creatorSelect" :department-id="1" v-else-if="'创建人' === modalName "
      />
      <user-select
          ref="inboundCreatorSelect" :department-id="2" v-else-if="'入库人' === modalName "
      />
    </b-modal>
  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { getCodeOptions } from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import store from '@/store'
import ProductSelect from '@/views/apps/product/ProductSelect'
import { useToast } from 'vue-toastification/composition'
/**/
import purchaseStore from '@/views/apps/purchaseinbound/purchaseinboundStore'
import WarehouseList from '@/views/apps/warehouse/warehouse-modal/WarehouseList'

export default {
  components: {
    WarehouseList,
    ProductSelect,
    UserSelect,
    CompanySelect
  },
  props: {
    warehouseId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseinbound')) store.registerModule('purchaseinbound', purchaseStore)


    onMounted(() => {
      state.condition = store.getters['purchaseinbound/getCondition']
    })
    const state = reactive({
      departmentId: 1,
      companyType: '',
      modalName: '',
      condition: {},
      //config
      rangeConfig: {
        mode: 'range',
        maxDate: 'today',
        //enableTime: true,
        dateFormat: 'Y-m-d'
      },

      singleModal: null,
    })
    const methods = {
      showSingleModal(modalName) {
        if (modalName === '签约主体') {
          state.companyType = 'Group'
        } else if (modalName === '供应商') {
          state.companyType = 'Supplier'
        }
        state.modalName = modalName
        state.singleModal.show()
      },
      onSelectSingle() {
        let data = {}
        switch (state.modalName) {
          case '仓库名称': {
            data = this.$refs.warehouseSelect.getSelected()
            if (data.length > 0) {
              state.condition.warehouse_id = data[0].warehouse_id
              state.condition.warehouseName = data[0].warehouse_name
            }
            break
          }
          case '创建人': {
            data = this.$refs.creatorSelect.getSelected()
            if (data.length > 0) {
              state.condition.creator = data[0].user_id
              state.condition.creatorName = data[0].full_name
            }
            break
          }
          case '入库人': {
            data = this.$refs.inboundCreatorSelect.getSelected()
            if (data.length > 0) {
              state.condition.inboundCreator = data[0].user_id
              state.condition.inboundCreatorName = data[0].full_name
            }
            break
          }
          case '供应商': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.supplier_id = data[0].company_id
              state.condition.supplierName = data[0].company_name
            }
            break
          }
        }
      },
      refreshTable() {
        let inbound_time = state.condition.inbound_time
        // if (inbound_time && !inbound_time.includes('至')) {
        //   toast.error("请选择范围时间")
        //   return false
        // }

        emit('refreshTable')
      },
      resetCondition() {
        store.commit('purchaseinbound/resetCondition')
        methods.refreshTable()
      },
    }
    return {
      ...toRefs(state),
      ...methods,
      isExpressStaff:store.state['purchaseinbound'].isExpressStaff,
      isSuper:store.state['purchaseinbound'].isSuper,
      //filter
      getCodeOptions
    }
  },

}
</script>

<style scoped>

</style>
