<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        title="采购订单"
    >
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="list"
          responsive
          hover
          :fields="tableColumns"
          show-empty
          empty-text="未找到记录"
          :busy="isBusy"
      >


      </b-table>

    </b-card>
    <b-card title="明细">
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="itemList"
          :tbody-tr-class="rowClass"
          responsive
          hover
          :fields="itemTableColumns"
          primary-key="id"
          show-empty
          empty-text="未找到记录"
          :busy="isBusy"
      >
        <template #cell(name)="data">

        <feather-icon
            :icon= "data.detailsShowing ? 'MinusSquareIcon' : 'PlusSquareIcon'"
            @click="data.toggleDetails"
        />
          {{data.value}}
        </template>
        <template #row-details="data">
          <b-card>
            <b-table
                ref="refListTable"
                class="position-relative"
                :items="data.item.ext.logItemList"
                responsive
                hover
                :fields="logItemColumns"
                primary-key="id"
                show-empty
                :busy="isBusy"
                empty-text="未找到记录"
            >
            </b-table>
          </b-card>

        </template>


      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import purchaseorderStore from './purchaseorderStore'
import purchaseOrderLogList from './purchaseOrderLogList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: ['order_id'],
  setup(props) {
    // Register module
    if (!store.hasModule('purchaseorder')) store.registerModule('purchaseorder', purchaseorderStore)



    const {
      tableColumns,
      list,
      itemList,
      itemTableColumns,
      logItemColumns,
      isBusy

      // UI
    } = purchaseOrderLogList({},props)

    return {
      tableColumns,
      list,
      itemList,
      itemTableColumns,
      logItemColumns,
      isBusy,


      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      toTime,
      toDate,
      rowClass(item, type) {
        if (!item || type !== 'row') { return }
        if (item.state === 0) {
          return 'table-danger'
        }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
