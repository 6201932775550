<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <search @refreshTable=refetchData   />
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          :tbody-tr-class="rowClass"
      >

        <!-- Columns 显示隐藏详细信息 -->
        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields"
                       :tbody-tr-class="rowClass">

                <template #cell(ext)="data">
                  {{ data.item.ext.code }}
                </template>

                <template #cell(purchase_return)="data">
                  {{ data.item.ext.purchase_return }}
                </template>

                <template #cell(name)="data">
                  [#{{ data.item.product_id }}]{{ data.item.name }}
                </template>

                <!-- Columns -->
                <template #cell(inventory_type)="data">
                  {{ getCodeLabel('inbound_inventory_type', data.item.inventory_type) }}
                </template>

                <template #cell(purchase_team)="data">
                  {{ getCodeLabel('purchase_team', data.item.purchase_team_id) }}
                </template>

                <template #cell(actions)="data">
                  <b-dropdown
                      variant="link"
                      no-caret
                      right

                  >

                    <template #button-content>
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                        v-if="data.item.trueqty > 0"
                        :to="{ name: 'apps-purchasereturnbound-edit', query: { inbound_item_id: data.item.id } }"
                    >
                      <feather-icon icon="FileTextIcon"/>
                      <span class="align-middle ml-50">退货申请</span>
                    </b-dropdown-item>

                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </b-card>
        </template>

        <!-- Columns -->
        <template #cell(inbound_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button
          >
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.inbound_id }}]{{ data.item.inbound_no }}
        </template>

        <template #cell(order_no)="data">
          [#{{ data.item.order_id }}]{{ data.item.order_no }}
        </template>

        <template #cell(status)="data">

          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_inbound_status',data.item.status)}`"

          >
            {{ getCodeLabel('purchase_inbound_status', data.value) }}
          </b-badge>
        </template>

        <template #cell(warehouse_name)="data">
          {{data.item.warehouse_name}}
          <feather-icon
              icon="UserIcon"
              size="16"
              @click="showWarehouseCharge(data.item)"
          />
        </template>

        <!-- Column: Type -->
        <template #cell(purchaseinbound_type)="data">
          {{ getCodeLabel('purchaseinbound_type', data.item.purchaseinbound_type) }}
        </template>

        <!-- Column: arrival_time -->
        <template #cell(arrival_time)="data">
          {{ toTime(data.item.arrival_time) }}
        </template>

        <!-- Column: delivery_time -->
        <template #cell(delivery_time)="data">
          {{ toTime(data.item.delivery_time) }}
        </template>

        <!-- Column: transport_type -->
        <template #cell(transport_type)="data">
          {{ getCodeLabel('transport_type', data.item.transport_type) }}
        </template>

        <!-- Column: delivery_type -->
        <template #cell(delivery_type)="data">
          {{ getCodeLabel('delivery_method_purchase', data.item.delivery_type) }}
        </template>

        <template #cell(status_sales)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_inbound_status_sales',data.value)}`"

          >
            {{ getCodeLabel('purchase_inbound_status_sales', data.value) }}
          </b-badge>
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(inbound_time)="data">
          {{ toTime(data.item.inbound_time) }}
        </template>

        <template #cell(inbound_creator)="data">
          {{ getCodeLabel('user', data.item.inbound_creator) }}
        </template>

        <template #cell(attach)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attach'"
                             :id="data.item.attach"
                             :object_type="'purchase_inbound'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>

        <template #cell(attachments_wh)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments_wh'"
                             :id="data.item.attachments_wh"
                             :object_type="'purchase_inbound'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>


        <template #cell(memo)="data">
          <!-- 备注 -->
          <div :id="`memo-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`memo-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(memo_wh)="data">
          <!-- 备注 -->
          <div :id="`memo_wh-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`memo_wh-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
              right
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="data.item.status === 2 || data.item.status === 1 "
                             :to="{ name: 'apps-purchaseinbound-edit', query: { id: data.item.id,readonly:true } }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看入库单</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 0"  @click="del(data.item.inbound_id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 0" @click="commit(data.item.inbound_id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交仓库</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 2&&data.item.status_sales===1"
                             @click="changeStatusSales(data.item)"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">确认入库</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 2&&data.item.status_sales!==2"
                             @click="showModal(data.item.inbound_id)"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">入库明细</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 0"
                             :to="{ name: 'apps-purchaseinbound-edit', query: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑入库通知单</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.status === 1" @click="resetPurchaseInbound(data.item.id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">撤销</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
          id="modal-inbound-item"
          ok-only
          ok-title="确认"
          cancel-title="取消"
          centered
          size="xl"
          title="查看入库明细"
          ref="modal"
      >
        <stock-inbound-item-edit ref="inboundItem" :inbound_id="inbound_id">

        </stock-inbound-item-edit>
      </b-modal>
    </b-card>

    <!--入库仓库人查看-->
    <b-modal
        id="modal-warehouse-charge"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        title="仓库负责人"
        ref="modalWarehouseCharge"
    >
      <warehouse-charge-list :warehouseId="itemWarehouseId" :warehouseName="itemWarehouseName" >

      </warehouse-charge-list>

    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import purchaseinboundUseList from './purchaseinboundUseList'
import purchaseinboundStore from './purchaseinboundStore'
import { useToast } from 'vue-toastification/composition'
import StockInboundItemEdit from '@/views/apps/stockinbounditem/inboundItem-modal/StockInboundItemList'
import { useRouter } from '@core/utils/utils'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import Search from '@/views/apps/purchaseinbound/search'
import WarehouseChargeList from "@/views/apps/warehousecharge/warehousecharge-modal/WarehouseChargeList";

export default {
  components: {
    WarehouseChargeList,
    Search,
    StockInboundItemEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
  },
  data() {
    return {
      inbound_id: 0,
      fields: [
        {
          key: 'ext',
          label: '商品69码'
        },
        {
          key: 'name',
          label: '商品名称',
          sortable: true
        },
        {
          key: 'purchase_team',
          label: '销售权',
          sortable: true
        },
        {
          key: 'is_gift',
          label: '是否赠品',
          sortable: true
        },
        {
          key: 'qty',
          label: '计划入库',
          sortable: true
        },
        {
          key: 'trueqty',
          label: '已入库',
          sortable: true
        },
        {
          key: 'purchase_return',
          label: '退货数量',
          sortable: true
        },
        {
          key: 'inventory_type',
          label: '盘点类型',
          sortable: true
        },
        {
          key: 'actions',
          label: '操作'
        },
      ],
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return
      }
      if (item.ext.tip_flag == true) {
        return 'table-danger'
      }
    },
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('purchaseinbound/state', {
        id: data.id,
        state: state
      })
          .then(res => {
            data.state = state
          })
    },
    showModal(id) {
      this.inbound_id = id
      this.$refs['modal'].show()
    }
  },
  props: {
    order_id: {
      type: Number,
    }
  },
  setup(props) {
    const toast = useToast()
    const { order_id } = props
    // Register module
    if (!store.hasModule('purchaseinbound')) store.registerModule('purchaseinbound', purchaseinboundStore)

    const {
      route,
      router
    } = useRouter()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinbound')) store.unregisterModule('purchaseinbound')
    })

    const commit = (id) => {
      store.dispatch('purchaseinbound/submitwarehouse', { id: id })
          .then((res) => {
            if (res.status === 200) {
              toast.success('提交成功')
              refetchData()
            }else{
              toast.success('操作异常，请重试')
            }
          })
    }

    const resetPurchaseInbound = function (purchaseInboundId) {
      store.dispatch('purchaseinbound/resetPurchaseInboundData', { inboundId: purchaseInboundId })
          .then(res => {
            if (res.status === 200) {
              toast.success(res.data.data)
              refetchData()
            } else {
              toast.error('撤销入库通知单失败!')
              refetchData()
            }
          })
    }

    const changeStatusSales = function (params) {
      store.dispatch('purchaseinbound/changeStatusSales', {
        id: params.inbound_id,
        type: 2
      })
          .then((res) => {
            if (res.status === 200) {
              toast.success('提交成功')
              refetchData()
            }
          })
    }

    const onUploaded = function (id, attachment, result) {
      this.uploadInfo = attachment
    }



    const state = reactive({
      modalWarehouseCharge:undefined,
      itemWarehouseId:0,
      itemWarehouseName:'',
    })

    const methods = {
      showWarehouseCharge(params) {
        state.itemWarehouseId = params.warehouse_id
        state.itemWarehouseName = params.warehouse_name
        state.modalWarehouseCharge.show()
      },
      del(id){
        if(confirm("确认删除？")) {
          store.dispatch('purchaseinbound/del', {
            id: id,
          }) .then((res) => {
                if (res.data.code === 0) {
                  toast.success(res.data.data)
                  refetchData()
                }else{
                  toast.error(res.data.data)
                }
              })
        }
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = purchaseinboundUseList({
      inbound_id: route.value.query.inbound_id,
      order_id:route.value.query.id>0?route.value.query.id:order_id,
      product_id:route.value.query.productId,
    })

    return {
      ...toRefs(state),
      ...methods,

      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toDate,
      // page
      commit,
      toTime,
      resetPurchaseInbound,
      changeStatusSales,
      onUploaded,
    }
  },

  //yang
  created() {
    this.orderId = this.$route.query.id || 0;

    if(this.orderId>0){
      this.productId = this.$route.query.productId || 0;
      this.status = 2
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
