import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCodeLabel, getCodeRemark } from '@core/utils/filter'
import { subtract } from '@core/utils/math/utils'
import { toDate, toTime } from '@core/utils/dayjs/utils'

export default function purchaseorderLogList(config,props) {

  const tableColumns = [
    { key: 'type_id', label: '类型', formatter:(value) => getCodeLabel("business_type",value) },
    { key: 'company_name', label: '采购主体'},
    { key: 'supplier_name', label: '供应商'},
    { key: 'contract_no', label: '协议编号'},
    { key: 'payment_method', label: '付款方式',formatter:(value) => getCodeLabel("agreement_pay_method",value) },
    { key: 'invoice_type', label: '发票类型',formatter:(value) => getCodeLabel("invoice_type",value) },
    { key: 'arrival_time', label: '要求到货日期',formatter:(value) => toDate(value) },
    { key: 'settlement_times', label: '结算次数' },
    { key: 'purchase_total', label: '预计采购费用'},
    { key: 'operator', label: '操作人',formatter:(value, key, item)=> getCodeLabel("user",item.ext.feed.user_id)},
    { key: 'operate_time', label: '操作时间',formatter:(value, key, item)=> toTime(item.ext.feed.create_time)},
  ]

  const itemTableColumns = [
    { key: 'name', label: '名称',formatter:(value, key, item)=> "[#" +item.product_id +"]" +item.name},
    { key: 'qty', label: '数量'},
    { key: 'product_cost_tax', label: '含税成本'},
    { key: 'sales_price', label: '预计供价'},
    { key: 'state',label:'是否删除',formatter:(value) => value === 0 ? "是":"否"}
  ]

  const logItemColumns = [
    { key: 'qty', label: '数量'},
    { key: 'product_cost_tax', label: '含税成本'},
    { key: 'sales_price', label: '预计供价'},
    { key: 'sales_period', label: '销售周期'},
    { key: 'expected_gross_profit_rate', label: '预计毛利率'},
    { key: 'state',label:'是否删除',formatter:(value) => value === 0 ? "是":"否"},
    { key: 'operator', label: '操作人',formatter:(value, key, cur)=> getCodeLabel("user",cur.ext.feed.user_id)},
    { key: 'operate_time', label: '操作时间',formatter:(value, key, cur)=> toTime(cur.ext.feed.create_time)},
  ]

  const list = ref([])
  const itemList = ref([])
  const isBusy = ref(false)
  const searchList = async () => {
    isBusy.value = true
    const res = await store
      .dispatch('purchaseorder/log', {
        id:props.order_id
      })
    isBusy.value = false
    const data = res.data.data
    list.value = data.list
    data.itemList.forEach(item =>{
      if (item.ext.logItemList.length > 1){
        item._showDetails = true
      }
    })
    itemList.value = data.itemList
  }
  onMounted( async ()=>{
    await searchList()
  })

  return {
    isBusy,
    tableColumns,
    list,
    itemList,
    itemTableColumns,
    logItemColumns,
  }
}
