import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function adssbcprocpurchasestocksale1dUseList(config,props) {
  // Use toast
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '序号', sortable: true },
    { key: 'pp', label: '品牌', sortable: true },
    { key: 'cgje', label: '采购金额', sortable: true },
    { key: 'zkje', label: '在库金额', sortable: true },
    { key: 'ztje', label: '在途金额', sortable: true },
    { key: 'j90txse', label: '近90天销售额', sortable: true },
    { key: 'rjxse', label: '日均销售额', sortable: true },
    { key: 'zzts', label: '周转天数', sortable: true },
    { key: 'cgsm', label: '采购说明', sortable: true },
    { key: 'ddbs', label: '订单标识', sortable: true },
    // { key: 'stat_ds', label: '统计日期标识', sortable: true },
  ]

  const refetchData = () => {
    refListTable.value.refresh()
  }


  const searchList = (ctx, callback) => {
    store
      .dispatch('purchaseorder/searchBrandData', {
        id:props.order_id,
      })
      .then(response => {
        const data = response.data.data
        const list = data
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    refetchData,
    refListTable,
    searchList,
    tableColumns,
    // Extra Filters
  }
}
