<template>
  <b-card
  >
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >
      <b-row>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="采购订单号:"
              label-for="orderNo"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.orderNo"
                name="orderNo"
                class="d-inline-block mr-1"
                size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品:"
              label-for="productCondition"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.productCondition"
                name="productCondition"
                class="d-inline-block mr-1"
                size="sm"
                placeholder="请输入69码或名称"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="订单状态:"
              label-for="orderStatus"
              label-size="sm"
          >
            <v-select
                id="status"
                :options="getCodeOptions('contract_status')"
                v-model="condition.status"
                name="orderStatus"
                class="select-size-sm"
                placeholder="请选择订单状态"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="签约主体:"
              label-for="company_id"
              label-size="sm"
          >
            <b-form-input
                id="company_id"
                size="sm"
                v-model="condition.companyName"
                name="companyId"
                @click="showSingleModal('签约主体')"
                readonly
                placeholder="点击搜索签约主体"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="供应商:"
              label-for="supplier_id"
              label-size="sm"
          >
            <b-form-input
                id="supplier_id"
                size="sm"
                v-model="condition.supplierName"
                name="supplierId"
                @click="showSingleModal('供应商')"
                readonly
                placeholder="点击搜索供应商"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="采购负责人:"
              label-for="purchaser_name"
              label-size="sm"
          >
            <b-form-input
                id="purchaser_name"
                size="sm"
                v-model="condition.purchaserName"
                name="purchaserName"
                @click="showSingleModal('采购负责人')"
                readonly
                placeholder="点击搜索采购负责人"
            />
          </b-form-group>
        </b-col>


        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="申请人:"
              label-for="applierName"
              label-size="sm"
          >
            <b-form-input
                id="applier_name"
                size="sm"
                v-model="condition.applierName"
                name="applierName"
                @click="showSingleModal('申请人')"
                readonly
                placeholder="点击搜索申请人"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="创建人:"
              label-for="creatorName"
              label-size="sm"
          >
            <b-form-input
                id="applier_name"
                size="sm"
                v-model="condition.creatorName"
                name="applierName"
                @click="showSingleModal('创建人')"
                readonly
                placeholder="点击搜索创建人"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="付款状态:"
              label-for="payStatus"
              label-size="sm"
          >
            <v-select
                id="status_pay"
                :options="getCodeOptions('purchase_status_over')"
                v-model="condition.status_pay"
                name="payStatus"
                class="select-size-sm"
                placeholder="请选择付款状态"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="是否已完成:"
              label-for="overStatus"
              label-size="sm"
          >
            <v-select
                id="status_over"
                :options="getCodeOptions('purchase_status_over')"
                :clearable="true"
                v-model="condition.status_over"
                name="overStatus"
                class="select-size-sm"
                placeholder="选择是否已完成"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="下单时间:"
              label-size="sm"
          >
            <flat-pickr
                v-model="condition.time"
                class="form-control"
                :config="rangeConfig"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="订单类型:"
              label-for="type_id"
              label-size="sm"
          >
            <v-select
                id="type_id"
                :options="getCodeOptions('business_type')"
                :clearable="true"
                v-model="condition.type_id"
                name="type_id"
                class="select-size-sm"
                placeholder="请选择订单类型"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="屏蔽供应商:"
              label-for="shielding_supplier"
              label-size="sm"
          >
            <v-select
                id="shielding_supplier"
                multiple
                label="title"
                :options="supplierArr"
                v-model="condition.shielding_supplier"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="平台单号:"
              label-for="bbm_order_no"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.bbm_order_no"
                name="bbm_order_no"
                class="d-inline-block mr-1"
                size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="平台品牌方:"
              label-for="brand"
              label-size="sm"
          >
            <v-select
                id="brand"
                label="title"
                :options="brandArr"
                v-model="condition.bbm_brand_id"
                :reduce="item => item.id"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="6"
        >
          <b-button
              variant="outline-primary"
              type="submit"
              class="mr-1"
          >
            <span class="align-right">查询</span>
          </b-button>

          <b-button
              variant="outline-primary"
              type="reset"
              class="mr-1"
          >
            <span class="align-right">重置</span>
          </b-button>

          <b-button
              variant="outline-primary"
              @click="exportToExcel()"
          >
            <span class="align-right">导出采购订单</span>
          </b-button>

        </b-col>
      </b-row>
    </b-form>

    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <company-select
          ref="companySelect" :companyType="companyType" v-if="modalName === '签约主体' || modalName === '供应商' "
      />

      <user-select
          ref="applierSelect" :department-id="departmentId" :is-all-state="true" v-else-if="'申请人' === modalName "
      />

      <user-select
          ref="creatorSelect" :department-id="departmentId" :is-all-state="true" v-else-if="'创建人' === modalName "
      />

      <user-select
          ref="purchaserSelect" :department-id="departmentId" :is-all-state="true" v-else-if="modalName === '采购负责人' "
      />
    </b-modal>
  </b-card>
</template>

<script>
import { computed, isRef, onBeforeUpdate, onMounted, onUnmounted, reactive, toRef, toRefs } from '@vue/composition-api'
import {getCodeLabel, getCodeOptions} from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import store from '@/store'
import purchaseorderStore from '@/views/apps/purchaseorder/purchaseorderStore'
import ProductSelect from '@/views/apps/product/ProductSelect'
import { useToast } from 'vue-toastification/composition'
import axios from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    ProductSelect,
    UserSelect,
    CompanySelect
  },
  props: {
    orderBy:'',
    isSortDirDesc:false,
  },
  data(){
    return{
      supplierArr:[],
      brandArr:[],
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseorder')) store.registerModule('purchaseorder', purchaseorderStore)

    const state = reactive({
      companyType: '',
      modalName: '',
      condition: {},

      //config
      rangeConfig: {
        mode: 'range',
        maxDate: 'today',
        //enableTime: true,
        dateFormat: 'Y-m-d'
      },

      //ref
      singleModal: null,
      // companySelect:null,
      // applierSelect:null,
      // purchaserSelect:null,
    })

    onMounted(()=>{
      state.condition = store.getters['purchaseorder/getCondition']
    })
    const methods = {
      showSingleModal(modalName) {
        if (modalName === '签约主体') {
          state.companyType = 'Group'
        } else if (modalName === '供应商') {
          state.companyType = 'Supplier'
        }

        state.modalName = modalName
        state.singleModal.show()
      },
      onSelectSingle() {
        let data = {}
        switch (state.modalName) {
          case '签约主体': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.company_id = data[0].company_id
              state.condition.companyName = data[0].company_name
            }
            break
          }
          case '供应商': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.supplier_id = data[0].company_id
              state.condition.supplierName = data[0].company_name
            }
            break
          }
          case '申请人': {
            data = this.$refs.applierSelect.getSelected()
            if (data.length > 0) {
              state.condition.applier_id = data[0].user_id
              state.condition.applierName = data[0].full_name
            }
            break
          }
          case '创建人': {
            data = this.$refs.creatorSelect.getSelected()
            if (data.length > 0) {
              state.condition.creator = data[0].user_id
              state.condition.creatorName = data[0].full_name
            }
            break
          }
          case '采购负责人': {
            data = this.$refs.purchaserSelect.getSelected()
            if (data.length > 0) {
              state.condition.purchaser_id = data[0].user_id
              state.condition.purchaserName = data[0].full_name
            }
            break
          }
        }
      },
      refreshTable() {
        let time = state.condition.time
        // if (time && !time.includes('至')) {
        //   toast.error("请选择范围时间")
        //   return false
        // }
        emit('refreshTable')
      },
      exportToExcel() {
        const condition = state.condition
        let shielding_supplier = '';
        if(condition.shielding_supplier !== undefined &&condition.shielding_supplier.length>0){
          let supplierArr = [];
          for(let i=0;i<condition.shielding_supplier.length;i++){
            supplierArr.push(condition.shielding_supplier[i].id);
          }
          shielding_supplier = supplierArr.join(",");
        }
        //删除一些条件
        /*condition.companyName = undefined
        condition.supplierName = undefined
        condition.purchaserName = undefined
        condition.applierName = undefined
        condition.creatorName = undefined*/
        const params = {
          order_by: props.orderBy,
          order_desc: props.isSortDirDesc === true ? 'desc':'',
        }
        Object.assign(params,condition)
        params.shielding_supplier = shielding_supplier
        params.companyName=null;
        params.supplierName=null;
        params.purchaserName=null;
        params.applierName=null;
        params.creatorName=null;
        store
            .dispatch('purchaseorder/exportToExcel', params)
            .then(res => {
              toast.success("导出成功")
              let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
              let filename = "采购订单-"+new Date().getTime();
              /*-------------*/
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(data);
              link.setAttribute('download',filename+'.xlsx')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
      },
      resetCondition() {
        state.condition = {
          shielding_supplier: [{"title":"其他商品入库专用供应商","id":4184},{"title":"线下销售退货自动入库专用供应商","id":24621}],
          companyName: undefined,
          supplierName: undefined,
          purchaserName: undefined,
          applierName: undefined,
          creatorName : undefined
        }
        store.commit('purchaseorder/updateCondition', state.condition)
        methods.refreshTable()
      },
    }

    const getSuppliers = function (){
      axios.post('/api/company/searchSupplier', {
      }).then(res => {
        const list = res.data.data.list
        for (let i = 0; i < list.length; i++) {
          this.supplierArr.push({"title":list[i].company_name,"id":list[i].company_id})
        }
      })
    }
    const getBbmBrand = function (){
      axios.post('/api/bbmbrand/listBrand', {
      }).then(res => {
        const list = res.data.data.list
        for (let i = 0; i < list.length; i++) {
          this.brandArr.push({"title":list[i].brand_acount,"id":list[i].brand_id})
        }
      })
    }
    return {
      ...toRefs(state),
      departmentId:computed(()=>store.getters['purchaseorder/getDepartmentId']),
      ...methods,
      getSuppliers,
      getBbmBrand,
      //filter
      getCodeOptions
    }
  },
  created() {
    this.getSuppliers()
    this.getBbmBrand()

  }
}
</script>

<style scoped>

</style>
