import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCodeRemark } from '@core/utils/filter'
import { multiply, subtract } from '@core/utils/math/utils'

export default function purchaseorderUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)


  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'order_no', label: '编号', sortable: true },
    // { key: 'inbound', label: '入库单' },
    // { key: 'type_id', label: '类型', sortable: true },
    { key: 'supplier_id', label: '供应商', sortable: true ,formatter:(value, key, item)=>(getCodeRemark("company",value) || item.supplier_name)},
    // { key: 'supplier_name', label: '供应商', sortable: true },
    /*{ key: 'supplier_contact_id', label: '供应商联系人ID', sortable: true },
    { key: 'supplier_contact_name', label: '供应商联系人姓名', sortable: true },
    { key: 'supplier_contact_mobile', label: '供应商联系电话', sortable: true },
    { key: 'supplier_order_no', label: '供应商合同编号', sortable: true },
    { key: 'trade_type', label: '贸易方式', sortable: true },*/
    { key: 'company_id', label: '签约主体', sortable: true ,formatter:(value, key, item)=>(getCodeRemark("company",value) || item.company_name)},
    // { key: 'company_name', label: '签约主体名称', sortable: true },
    /*{ key: 'purchaser_id', label: '采购员ID', sortable: true },
    { key: 'purchaser_name', label: '采购员名称', sortable: true },
    { key: 'purchaser_mobile', label: '采购员联系电话', sortable: true },
    { key: 'contract_id', label: '对方合同ID', sortable: true },
    { key: 'contract_no', label: '对方合同编号', sortable: true },
    { key: 'contract_type', label: '对方合同类型', sortable: true },
    { key: 'invoice_type', label: '发票类型', sortable: true },
    { key: 'payment_method', label: '付款方式', sortable: true },
    { key: 'need_advance', label: '是否需要预付款', sortable: true },
    { key: 'settlement_method', label: '结算方式', sortable: true },
    { key: 'settlement_times', label: '结算次数', sortable: true },*/
    // { key: 'delivery_type', label: '物流方式  delivery_method_purchase', sortable: true },
    // { key: 'extra_cost_subtotal', label: '附加费', sortable: true },
    { key: 'purchase_total', label: '订单金额', sortable: true },
    { key: 'total_paid', label: '已付金额', sortable: true },
    { key: 'return_goods_cost',label:'总退款',formatter:(value,key,item) => item.refund_amount||0},
    //{ key: 'inbound_amount',headerTitle:'sum(明细的“到货数量” - “退货数量”) * 含税成本', label: '入库总金额', formatter:(value,key,item) =>item.ext.inbound_amount},
    { key: 'inbound_amount',headerTitle:'sum(明细的“到货数量” - “退货数量”) * 调整后单价', label: '入库总金额', formatter:(value,key,item) =>item.ext.inbound_amount},

    { key: 'invoice',headerTitle:'已付金额 - sum(明细中的“退款”) - 入库总金额', label: '资金敞口', formatter:(value,key,item) =>subtract((item.total_paid||0),(item.ext.inbound_amount||0),(item.refund_amount||0 ))  },
    // { key: 'invoice',headerTitle:'资金敞口=已付金额-已入库总金额-总退款金额', label: '资金敞口', formatter:(value,key,item) =>subtract((item.total_paid||0),(item.total_pay||0),(item.refund_amount||0 ))  },
    // { key: 'invoice',headerTitle:'资金敞口= (已付金额 - 总退款) -（已入库总金额 - 总退货金额）', label: '资金敞口', formatter:(value,key,item) =>subtract(subtract((item.total_paid||0),(item.refund_amount||0 )),subtract(item.total_pay||0,item.ext.return_goods_cost))  },

     { key: 'true_pay_time',label:'实际支付日期',formatter:(value,key,item) => item.ext.true_pay_time||""},
    { key: 'invoice_amount', label: '发票金额', sortable: true },

    // { key: 'total_pay', label: '入库金额', sortable: true },

    { key: 'total_qty', label: '订单数量', sortable: true },
    { key: 'zrks',headerTitle:'sum(到货数量-退货数量)', label: '总入库数', formatter:(value,key,item) =>subtract((item.total_delivery_qty||0),(item.refund_qty||0 )) },
    // { key: 'invoicing_status', label: '开票状态', sortable: true },
    // { key: 'invoicing_amount', label: '开票金额', sortable: true },
    // { key: 'total_delivery_qty', label: '已入库数量', sortable: true },
    // { key: 'refund_amount', label: '总退款',sortable: true},
    // { key: 'refund_qty', label: '总退货数量',sortable: true},
    { key: 'remark', label: '备注', sortable: true },
    { key: 'creator', label: '创建人'},
    { key: 'applier_name', label: '申请人', sortable: true },
    { key: 'remark_purchase', label: '采购专用备注', sortable: true },
    { key: 'pick_method', label: '提货方式'}, //pickup_method_CG
    { key: 'use_type', label: '采购单用途', sortable: true },
    { key: 'status', label: '订单状态', sortable: true },
    { key: 'status_pay', label: '付款状态', sortable: true },

    { key: 'status_over', label: '是否已完成'},
    //{ key: 'stockup_status', label: '备采状态'},
    //{ key: 'delivery_info', label: '收获人信息'},
    // { key: 'state', label: '状态', sortable: true },
    // { key: 'create_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人ID', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
    //{ key: 'pick_method', label: '提货/发货方式    采购协议：pickup_method_CG', sortable: true },
    { key: 'together', label: '协作人员'},
    { key: 'first_pay_time', label: '首次付款时间'},
    { key: 'last_inbound_time', label: '最后入库时间'},
    { key: 'close_status', label: '关闭状态'},
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref('order_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['purchaseorder/getCondition']}
    let shielding_supplier = '';
    if(condition.shielding_supplier !== undefined &&condition.shielding_supplier.length>0){
      let supplierArr = [];
      for(let i=0;i<condition.shielding_supplier.length;i++){
        supplierArr.push(condition.shielding_supplier[i].id);
      }
      shielding_supplier = supplierArr.join(",");
    }
    //删除一些条件
    condition.companyName = undefined
    condition.supplierName = undefined
    condition.purchaserName = undefined
    condition.applierName = undefined
    condition.creatorName = undefined
    const params = {
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_id:config.order_id,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
    }
    Object.assign(params,condition)
    params.shielding_supplier = shielding_supplier
    store
      .dispatch('purchaseorder/search', params)
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购订单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
