import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function purchaseinboundUseList(config) {
  // Use toast
  const toast = useToast()

  const purchase_order_id = config.order_id
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'inbound_id', label: 'ID', sortable: true },
    { key: 'inbound_no', label: '入库单编号', sortable: true },
    // { key: 'order_id', label: '采购单ID', sortable: true },
    { key: 'order_no', label: '采购单编号', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    { key: 'warehouse_name', label: '仓库名称'},
    { key: 'delivery_type', label: '物流方式'},
    //{ key: 'transport_type', label: '运输方式', sortable: true },
    { key: 'delivery_time', label: '发货时间', sortable: true },
    { key: 'arrival_time', label: '预计到达时间', sortable: true },
    //{ key: 'carrier', label: '承运商', sortable: true },
    //{ key: 'express_no', label: '快递单号', sortable: true },
    { key: 'memo', label: '采购备注', sortable: true },
    { key: 'status', label: '入库状态', sortable: true },
    { key: 'status_sales', label: '采购确认', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    // { key: 'add_time', label: '创建时间', sortable: true },
    { key: 'creator', label: '创建人'},
    { key: 'attach', label: '附件'},
    { key: 'attachments_wh', label: '仓库附件'},
    { key: 'memo_wh', label: '仓库备注'},
    { key: 'inbound_time', label: '入库时间', sortable: true },
    { key: 'inbound_creator', label: '入库人'},
    // { key: 'modify_time', label: '修改时间', sortable: true },
    // { key: 'updator', label: '修改人', sortable: true },
        { key: 'actions', label: '操作' },
  ]
  /*if (purchase_order_id || store.state['purchaseinbound'].isWarehouseStaff){
    tableColumns.splice(tableColumns.length -1,1)
  }*/

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('inbound_id')
  const isSortDirDesc = ref(true)
  const searchType = config

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['purchaseinbound/getCondition']}
    condition.warehouseName = undefined
    condition.creatorName = undefined
    condition.inboundCreatorName = undefined
    condition.supplierName = undefined
    /*  product_id:this.productId>0?this.productId:"",
      status:this.status===2?2:""*/
    const params = {
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
      status:'0,1,2',
      inbound_id:config.inbound_id,
      order_id:purchase_order_id
    }
    if( config.product_id>0){
      condition.product_id = config.product_id
      condition.status = 2
    }

    Object.assign(params,condition)
    store
      .dispatch('purchaseinbound/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    searchType,
    refetchData,

    // Extra Filters
  }
}
