<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

        </b-row>
        <b-row>
          <search @refreshTable=refetchData :orderBy="orderBy" :isSortDirDesc="isSortDirDesc" />
        </b-row>



      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- full detail on click -->
        <template #row-details="data">
          <main-table-item-list :order_id="data.item.order_id" :is-watcher="data.item.ext.isWatcher" />
        </template>

        <template #cell(order_no)="data">
          <feather-icon
              :icon= "data.detailsShowing ? 'MinusSquareIcon' : 'PlusSquareIcon'"
              @click="data.toggleDetails"
          />

          [#{{data.item.id}}]
          <b-link
              :to="oaMenu.changing.includes(data.item.status) ? { name: 'apps-purchaseorder-view', query: { id: data.item.id} }
                :{ name: 'apps-purchaseorder-edit', query: { id: data.item.id },params:{readonly:1} }"
              target="_blank"
          >
            {{data.value}}
          </b-link>
          <br>{{data.item.bbm_order_no}}
        </template>

<!--        <template #cell(inbound)="data">
          <feather-icon
              icon= "CpuIcon"
              :badge="data.item.ext['inbound_times']"
              badge-classes="badge-danger"
              @click="showInboundModal(data.item)"
              size="21"
          />
        </template>-->


<!--        <template #cell(supplier_id)="data">
          <div :id="`supplier_id-row-${data.item.id}`">
            {{ data.value.substring(0, 3)}}
            <span v-if="data.value.length > 3"> ...</span>
          </div>

          <b-tooltip
              :target="`supplier_id-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>


        <template #cell(company_id)="data">
          <div :id="`company_id-row-${data.item.id}`">
            {{ data.value.substring(0, 3)}}
            <span v-if="data.value.length > 3"> ...</span>
          </div>

          <b-tooltip
              :target="`company_id-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>-->

        <template #cell(total_pay)="data">
          <div :id="`total_pay-row-${data.item.id}`">
            {{ data.value || 0 }}
          </div>
<!--          <b-tooltip
              :target="`total_pay-row-${data.item.id}`"
              placement="top"
          >
            已入库数量： {{ data.item.total_delivery_qty || 0 }}
            <br>
            已入库总金额： {{ data.value || 0 }}
          </b-tooltip>-->

        </template>

        <template #cell(purchase_total)="data">
          <div :id="`purchase_total-row-${data.item.id}`">
            {{ data.value || 0 }}
          </div>

<!--          <b-tooltip
              :target="`purchase_total-row-${data.item.id}`"
              placement="top"
          >
            采购数量： {{ data.item.total_qty || 0 }}
            <br>
            预计金额： {{ data.value || 0 }}
          </b-tooltip>-->
        </template>

        <template #cell(refund_amount)="data">
          <div :id="`refund_amount-row-${data.item.id}`">
            {{ data.value || 0 }}
          </div>

<!--          <b-tooltip
              :target="`refund_amount-row-${data.item.id}`"
              placement="top"
          >
            总退款数量： {{ data.item.refund_qty || 0 }}
            <br>
            总退款金额： {{ data.value || 0 }}
          </b-tooltip>-->
        </template>

        <template #cell(refund_qty)="data">
          {{ data.value || 0 }}
        </template>

        <template #cell(total_qty)="data">
          {{ data.item.total_qty || 0 }}
        </template>

        <template #cell(total_delivery_qty)="data">
          {{ data.item.total_delivery_qty || 0 }}
        </template>

        <template #cell(type_id)="data">
          {{ getCodeLabel('business_type', data.item.type_id) }}
        </template>

        <template #cell(pick_method)="data">
          {{ getCodeLabel('pickup_method_CG', data.item.pick_method) }}
        </template>

        <template #cell(use_type)="data">
          {{ getCodeLabel('purchase_use_type', data.item.use_type) }}
        </template>

        <!-- 附加费 -->
        <template #cell(extra_cost_subtotal)="data">
          <!-- <b-button variant="primary" v-b-modal.FeeModal >添加</b-button> -->
          <div :id="`extra_cost_subtotal-row-${data.item.id}`">
            {{ data.value }}
            <span v-if="data.value > 1000000"> ...</span>
            <feather-icon icon="EditIcon" @click="showFee(data.item)"/>
          </div>
          <!--
            鼠标触发提示框
           <b-tooltip
               :target="`extra_cost_subtotal-row-${data.item.id}`"
               placement="top"
           >
             {{ data.value}}
           </b-tooltip>-->
        </template>

        <template #cell(together)="data">
          <div v-b-tooltip.hover.top="data.item.ext.togetherName">
            {{  data.item.ext.togetherName.substring(0, 6) }}
            <span v-if="data.item.ext.togetherName.length > 6"> ...</span>
          </div>
        </template>

        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
        </template>

        <!-- 备注 -->
        <template #cell(remark)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{  data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
            <feather-icon  icon="EditIcon" @click="showRemark(data.item)"/>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <!-- 采购专用备注 -->
        <template #cell(remark_purchase)="data">

          <div :id="`remark_purchase-row-${data.item.id}`">
            <span v-if="data.value.length > 3"> ...</span>
            <feather-icon  icon="EditIcon" @click="showRemarkPurchase(data.item)"/>
          </div>
          <b-tooltip
              :target="`remark_purchase-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('contract_status',data.item.status)}`"
          >
            {{ getCodeLabel('contract_status', data.value) }}
          </b-badge>
        </template>

        <template #cell(status_pay)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_status_over', data.item.status_pay)}`"
          >
          {{ getCodeLabel('purchase_status_over', data.item.status_pay) }}
          </b-badge>
        </template>

        <template #cell(status_over)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_status_over', data.item.status_over)}`"
          >
            {{ getCodeLabel('purchase_status_over', data.item.status_over) }}
          </b-badge>
          <b-badge
              v-if="data.item.bbm_is_over === 1"
              pill
              variant="light-success"
          >
            已关单
          </b-badge>
        </template>

        <template #cell(first_pay_time)="data">
          {{ data.item.ext.firstPayTime }}
        </template>

        <template #cell(last_inbound_time)="data">
          {{ data.item.ext.lastInboundTime }}
        </template>

        <template #cell(stockup_status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stockup_status',data.item.ext.stockupStatus)}`"
          >
            {{ getCodeLabel('stockup_status', data.item.ext.stockupStatus) }}
          </b-badge>
        </template>

        <template #cell(invoicing_status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('invoice_status',data.item.invoicing_status)}`"
          >
            {{ getCodeLabel('invoice_status', data.item.invoicing_status) }}
          </b-badge>
        </template>

        <template #cell(close_status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_close_status',data.item.close_status)}`"
          >
            {{ getCodeLabel('purchase_close_status', data.item.close_status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
              right
              v-if="!data.item.ext.isWatcher"
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item v-b-modal.modal-order-log @click="order_id = data.item.id" >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看修改日志</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.modal-order-ads @click="order_id = data.item.id" >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">备采信息</span>
            </b-dropdown-item>

            <b-dropdown-item @click="toPDF(data.item.id)" >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">生成PDF</span>

            </b-dropdown-item>

            <b-dropdown-item
                v-b-modal.user-modal @click="changeOrderId(data.item)" v-if="user.role_id==14"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">修改创建人</span>
            </b-dropdown-item>
            <b-dropdown-item
                :to="{ name: 'apps-purchaseinbound-edit', query: { order_id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">创建入库通知单</span>
            </b-dropdown-item>
            <b-dropdown-item
                @click="showInboundModal(data.item)"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看入库通知单列表</span>
              <b-badge variant="danger" >
                {{ data.item.ext['inbound_times'] }}
              </b-badge>
            </b-dropdown-item>
            <b-dropdown-item
                :to="{ name: 'apps-purchaserefund-list', query: { order_id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看退款列表</span>
            </b-dropdown-item>

            <b-dropdown-item
                v-if="data.item.creator === user.user_id || user.role_id === 14"
                @click="showWatcherModal(data.item)"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">观察员</span>
            </b-dropdown-item>

            <!--            <b-dropdown-item v-if="data.item.status === 2"-->
            <!--                             :to="{ name: 'apps-purchasepaid-list', query: { order_id: data.item.id } }"-->
            <!--            >-->
            <!--              <feather-icon icon="FileTextIcon"/>-->
            <!--              <span class="align-middle ml-50">采购付款单列表(手动)</span>-->
            <!--            </b-dropdown-item>-->

            <b-dropdown-item v-if="data.item.status === 0&&data.item.payment_method==6" @click="commit(data.item)"
                             v-b-modal.modal-money
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">预付款</span>
            </b-dropdown-item>

<!--            <b-dropdown-item-->
<!--                v-if="[0,2,3,5,6,8].includes(data.item.status) && ![1,3].includes(data.item.ext.stockupStatus)"-->
<!--                @click="createStockUp(data.item.order_id)"-->
<!--            >-->
<!--              <feather-icon icon="ShoppingBagIcon"/>-->
<!--              <span class="align-middle ml-50">生成备采单</span>-->
<!--            </b-dropdown-item>-->

            <div v-if="data.item.type_id && data.item.type_id==3">

              <div v-if="user.role_id == 11 || user.role_id == 12">

                <b-dropdown-item v-if="data.item.status === 1"
                                 @click="VMICommit(data.item.order_id,2)"
                >
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">审批通过</span>
                </b-dropdown-item>
              </div>
              <div v-else>
                <b-dropdown-item v-if="oaMenu.edit.includes(data.item.status) "
                                 @click="VMICommit(data.item.order_id,1)"
                >
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">提交审核</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="oaMenu.finish.includes(data.item.status)"
                                 :to="{ name: 'apps-purchasepayment-edit', query: { order_id: data.item.id } }"
                >
                  <feather-icon icon="FileTextIcon"/>
                  <span class="align-middle ml-50">创建采购付款申请单</span>
                </b-dropdown-item>
                <b-dropdown-item
                    v-if="oaMenu.edit.includes(data.item.status)"
                    @click="toEdit(data.item.id)"
                >
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">编辑</span>
                </b-dropdown-item>
              </div>
              <b-dropdown-item v-if="data.item.status === 2"
                               :to="{ name: 'apps-purchasepayment-list', query: { order_id: data.item.id } }"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">采购付款申请单列表</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="data.item.status === 1 || data.item.status === 2"
                               @click="VMICommit(data.item.order_id,0)"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">退回</span>
              </b-dropdown-item>

            </div>
            <div v-else>
              <b-dropdown-item v-if="oaMenu.finish.includes(data.item.status)"
                               :to="{ name: 'apps-purchasepayment-edit', query: { order_id: data.item.id } }"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">创建采购付款申请单</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="data.item.status === 2||data.item.status === 5"
                               :to="{ name: 'apps-purchasepayment-list', query: { order_id: data.item.id } }"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">采购付款申请单列表(OA)</span>
              </b-dropdown-item>
              <oa-component :model="data.item" url="purchaseorder" table="purchase_order"
                            @refreshTable=refetchData
              ></oa-component>

              <b-dropdown-item
                  v-if="oaMenu.edit.includes(data.item.status)"
                  @click="toEdit(data.item.id)"
              >
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="data.item.status === 2 && data.item.status_pay === 2 && data.item.bbm_is_over !== 1 && data.item.bbm_order_no !== null"
                               @click="orderOver(data.item.order_id)"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">完成BBM订单</span>
              </b-dropdown-item>
            </div>


            <b-dropdown-item v-if="data.item.status === 0" @click="del(data.item.id)" >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

            <b-dropdown-item  @click="showCloseRemark(data.item)" >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">关闭采购单</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
        id="modal-order-log"
        hide-footer
        centered
        size="xl"
        title="修改日志"
    >
      <purchase-order-log :order_id="order_id"  ></purchase-order-log>
    </b-modal>
    <!--备采信息-->
    <b-modal
            id="modal-order-ads"
            hide-footer
            centered
            size="xl"
            title="备采信息"
    >
      <ads-list :order_id="order_id"  ></ads-list>
    </b-modal>
    <!-- 预付款弹出窗口 -->
    <b-modal
        id="modal-money"
        ok-only
        ok-title="关闭"
        centered
        size="lg"
        title="填写预付款"
        v-if="payment_method==6"
        ref="money"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="剩余预付款"
              label-for="register_address"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="prepaid_amount"
                readonly=""
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="支付金额"
              label-for="register_address"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="payMoney"
                @change="changepayMoney($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
          variant="primary"
          @click="submitMoney"
      >
        <span class="text-nowrap">提交</span>
      </b-button>

    </b-modal>
    <!-- 备注弹出窗口 -->
    <b-modal
        id="modal-remark"
        ok-only
        ok-title="确认"
        @ok="saveRemark"
        cancel-title="取消"
        centered
        size="lg"
        title="修改备注"
        ref="remarkModal"
    >
      <b-card :header="`编号：${selectedItem.order_no}`">
        <b-form-textarea
            id="textarea"
            v-model="remarkInfo"
            rows="3"
            max-rows="6"
        />
      </b-card>
    </b-modal>

    <!-- 备注弹出窗口 -->
    <b-modal
        id="modal-remark_purchase"
        ok-only
        ok-title="确认"
        @ok="saveRemarkPurchase"
        cancel-title="取消"
        centered
        size="lg"
        title="修改备注"
        ref="remarkPurchaseModal"
    >
      <b-card :header="`编号：${selectedItem.order_no}`">
        <b-form-textarea
            id="textarea"
            v-model="remarkPurchaseInfo"
            rows="3"
            max-rows="6"
        />
      </b-card>
    </b-modal>


    <!-- 附加费弹出窗口 -->
    <b-modal
        id="feeModal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="xl"
        title="附加费列表"
        ref="feeModal"
        @hidden="resetTable"
    >
      <fee-list
          ref="FeeList" :item="item"
      >
      </fee-list>
    </b-modal>


    <!-- 员工弹出窗口 -->
    <b-modal
        id="user-modal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        title="人员列表"
        ref="userModal"
        @ok="saveCreator"
    >
      <user-select
          ref="UserList"
      >
      </user-select>
    </b-modal>

    <!-- 采购入库单弹出窗口 -->
    <b-modal
        id="inbound-modal"
        hide-footer
        size="xl"
        title="采购入库单"
        ref="inboundModal"
    >
      <purchase-inbound-list :order_id="curRow.id" />
    </b-modal>

    <!-- 采购观察员弹出窗口 -->
    <b-modal
        id="watcher-modal"
        hide-footer
        size="lg"
        :title="`${curRow.order_no} 采购观察员列表`"
        ref="watcherModal"
    >
      <purchase-order-watcher-list :order-id="curRow.id" @hideModal="watcherModal.hide()" ></purchase-order-watcher-list>
    </b-modal>

    <b-modal
        id="pdfModal"
        centered
        title="PDF"
        ref="pdfModal"
        hide-footer
    >
      <attachment-upload theme="preview"
                         attachment_id="attachments"
                         :id="pdf_id"
                         :readonly="true"
                         v-if="pdf_id"
      />
      <div v-else>
        <p class="my-4">正在生成，请稍候!</p>
      </div>

    </b-modal>

    <!-- 加载modal -->
    <b-modal
        id="loadingModal"
        centered
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        :visible="loadingVisible"
    >
      <p class="my-4">正在提交，请稍候!</p>
    </b-modal>

    <b-modal
        id="modal-remark"
        ok-only
        ok-title="确认"
        @ok="saveCloseRemark"
        cancel-title="取消"
        centered
        size="lg"
        title="原因"
        ref="closeRemarkModal"
    >
      <b-card>
        <b-form-textarea
            id="textarea"
            v-model="closeRemarkInfo"
            rows="3"
            max-rows="6"
        />
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import axios from '@axios'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,getCodeRemark } from '@core/utils/filter'
import purchaseorderUseList from './purchaseorderUseList'
import purchaseorderStore from './purchaseorderStore'
import { useToast } from 'vue-toastification/composition'
import FeeList from '@/views/apps/fee/FeeList'
import oaStore from '@/views/apps/oa/oaStore'
import { oaMenu } from '@/views/apps/oa/oaMenu'
import OaComponent from '@/views/apps/oa/OaComponent'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import { getUserData } from '@/auth/utils'
import CompanySelect from '../company/CompanySelect'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '../../../@core/components/toastification/ToastificationContent'
import Search from '@/views/apps/purchaseorder/search'
import MainTableItemList from '@/views/apps/purchaseorderitem/MainTableItemList'
import PurchaseInboundList from '@/views/apps/purchaseinbound/PurchaseInboundList'
import PurchaseOrderWatcherList from '@/views/apps/purchaseorderwatcher/PurchaseOrderWatcherList'
import purchaseinboundStore from '@/views/apps/purchaseinbound/purchaseinboundStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import { useRouter } from '@core/utils/utils'
import PurchaseOrderLog from '@/views/apps/purchaseorder/PurchaseOrderLog'
import AdsList from '@/views/apps/adssbcprocpurchasestocksale1d/AdsSbcProcPurchaseStockSale1dList'
import purchasecloseStore from "@/views/apps/purchaseclose/purchasecloseStore";

export default {
  components: {
    PurchaseOrderLog,
    AdsList,
    AttachmentUpload,
    PurchaseOrderWatcherList,
    PurchaseInboundList,
    MainTableItemList,
    Search,
    UserSelect,
    OaComponent,
    CompanySelect,
    FeeList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      selectedItem: {},
      dataList: [],
      fee: {
        fee_id: null,
        fee_order_type: null,
        relational_id: null,
        fee_type: null,
        amount: null,
        status: null,
        state: null,
        attachments: null,
        create_time: null,
        creator: null,
        update_time: null,
        updator: null,
      },
      // 选中行的数据
      item: {},
      payment_method: 0,
      prepaid_amount: 0,
      payMoney: 0,
      order_id: 0,
      user: {},
      remarkInfo: null,
      remarkPurchaseInfo: null,
      findSelect: '采购订单编号',
      searchKeyName: '按照销售订单编号搜索，请输入关键字...',

      advanced_search_modal: false,
      companyType: '',
      modalName: '',
      userDepartment: 'purchase',
      supplierName: '',
      companyName: '',

      // productId:'',
      // orderNo:'',
      // supplierId:'',
      purchaserName: '',
      // companyId:'',
      applierName: '',
      // orderStatus: '',
      // payStatus:'',
      // overStatus:'',
      allPurchaseUser:[],
      loadingVisible: false,
      closeRemarkInfo:'',
    }
  },
  methods: {
    showRemark(item) {
      this.selectedItem = item
      this.remarkInfo = item.remark
      this.$refs['remarkModal'].show()
    },
    showRemarkPurchase(item) {
      this.selectedItem = item
      this.remarkPurchaseInfo = item.remark_purchase
      this.$refs['remarkPurchaseModal'].show()
    },
    showFee(item) {
      // item: 采购订单参数
      this.item = item
      this.item.fee_type_id = 1
      this.item.relational_no = item.order_no
      this.$refs['feeModal'].show()
    },
    resetTable() {
      this.$refs.refListTable.refresh()
    },
    showCloseRemark(item) {
      this.selectedItem = item
      this.$refs['closeRemarkModal'].show()
    },

  },
  setup() {

    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseorder')) store.registerModule('purchaseorder', purchaseorderStore)
    if (!store.hasModule('oa')) store.registerModule('oa', oaStore)
    if (!store.hasModule('purchaseinbound')) store.registerModule('purchaseinbound', purchaseinboundStore)
    if (!store.hasModule('purchaseclose')) store.registerModule('purchaseclose', purchasecloseStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseorder')) store.unregisterModule('purchaseorder')
      if (store.hasModule('oa')) store.unregisterModule('oa')
      if (store.hasModule('purchaseinbound')) store.unregisterModule('purchaseinbound')
      if (store.hasModule('purchaseclose')) store.unregisterModule('purchaseclose')
    })

    const saveRemark = function () {
      this.selectedItem.remark = this.remarkInfo
      store.dispatch('purchaseorder/save', this.selectedItem)
          .then(res => {
            if (res.data.code === 0) {
              toast.success('修改成功')
            } else {
              toast.error('修改失败')
            }
          })
    }

    const orderOver = function (orderId) {
      store.dispatch('purchaseorder/orderOver', {
        id: orderId
      }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data.success) {
            toast.success(res.data.data.msg)
            this.refetchData()
          } else {
            toast.error(res.data.data.msg)
          }
        } else {
          toast.error('BBM订单关闭失败')
        }
      })

    }

    const del = function (orderId) {
      store.dispatch('purchaseorder/del', {
        id: orderId
      }).then(res => {
        if (res.data.code === 0) {
            toast.success(res.data.data)
            this.refetchData()
        } else {
          toast.error(res.data.data)
        }
      })

    }

    const saveRemarkPurchase = function () {
      this.selectedItem.remark_purchase = this.remarkPurchaseInfo
      store.dispatch('purchaseorder/save', this.selectedItem)
          .then(res => {
            if (res.data.code === 0) {
              toast.success('修改成功')
              this.refetchData
            } else {
              toast.error('修改失败')
            }
          })
    }

    const {route} = useRouter();
    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      /*searchKeyNameColumn,*/
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      // UI
    } = purchaseorderUseList({
      order_id: route.value.query.order_id,
    })

    const commit = function (item) {
      this.payment_method = item.payment_method
      if (item.payment_method != 6) {
        store.dispatch('purchaseorder/commit', {
          order_id: item.id,
          status: 2
        })
            .then(res => {
              if (res.data.code == 0) {
                toast.success('提交成功')
                refetchData()
              } else {
                toast.error(res.data.data)
              }
            })
      } else {
        this.order_id = item.order_id
        store.dispatch('purchaseorder/getPrepaidAmount', {
          id: item.supplier_id
        })
            .then(res => {
              this.prepaid_amount = res.data.data.prepaid_amount
            })
      }

    }

    const submitMoney = function () {
      if (this.payMoney === 0 || this.payMoney === '' || this.payMoney === undefined) {
        toast.error('请填写正确的数字!')
        return false
      }
      store.dispatch('purchaseorder/commit', {
        order_id: this.order_id,
        prepaid_amount: this.payMoney,
      })
          .then(res => {
            if (res.data.code == 0) {
              toast.success('提交成功')
              this.$refs['money'].hide()
              refetchData()
            } else {
              toast.error(res.data.data)
              refetchData()
              this.$refs['money'].hide()
            }
          })
    }

    const changepayMoney = function (event) {
      const cRegExp = /^(0|[1-9][0-9]*)(\.\d+)?$/
      if (!cRegExp.test(event)) {
        toast.error('金额必须是正数!')
        this.payMoney = 0
      }

    }

    const changeSelect = function (e) {
      if (e === '采购订单编号') {
        this.searchKeyName = '按照销售订单编号搜索，请输入关键字...'
        this.searchKeyNameColumn = '1'
      } else if (e === '商品69码') {
        this.searchKeyName = '按照商品69码搜索，请输入关键字...'
        this.searchKeyNameColumn = '2'
      } else if (e === '商品名称') {
        this.searchKeyName = '按照商品名称搜索，请输入关键字...'
        this.searchKeyNameColumn = '3'
      }
    }

    const changeState = function (data, params) {
      if (params == 'status_pay') {
        const state = 3 - data.status_pay
        store.dispatch('purchaseorder/state', {
          id: data.order_id,
          state: state,
          type: 'status_pay'
        })
            .then(res => {
              if (res.data.code == 0) {
                data.status_pay = state
              } else {
                toast.error(res.data.data)
              }
            })
      }
      if (params == 'status_over') {
        const state = 3 - data.status_over
        store.dispatch('purchaseorder/state', {
          id: data.order_id,
          state: state,
          type: 'status_over'
        })
            .then(res => {
              if (res.data.code == 0) {
                data.status_over = state
              } else {
                toast.error(res.data.data)
              }
            })
      }
    }

    const saveCreator = function () {
      let data = this.$refs.UserList.getSelected()[0]
      store.dispatch('purchaseorder/changeCreator', {
        id: this.order_id,
        userid: data.id,
      })
          .then(res => {
            if (res.data.code == 0) {
              toast.success(res.data.data)
            } else {
              toast.error(res.data.data)
            }
            refetchData()
          })
    }

    const changeOrderId = function (data) {
      this.order_id = data.order_id
    }

    const state = reactive({
      curRow:{},

      inboundModal:undefined,
      watcherModal:undefined,

      pdfModal:undefined,
      pdf_id:undefined,
    })

    const methods = {
      toPDF(id){
        state.pdf_id = undefined;
        state.pdfModal.show()
        store.dispatch('purchaseorder/toPDF', { id: id })
            .then(res => {
              if (res.data.code === 0) {
                state.pdf_id = res.data.data.id
              } else {
                state.pdfModal.hide()
                toast.error(res.data.data)
              }
            })
      },
      showInboundModal(row){
        state.curRow = row
        store.commit('purchaseinbound/resetCondition')
        state.inboundModal.show()
      },
      showWatcherModal(row){
        state.curRow = row
        state.watcherModal.show()
      },
    }

    const createStockUp = function(param) {
      this.loadingVisible = true
      store.dispatch('purchaseorder/createStockUp', {id: param,}).then(res => {
        if (res.data.code == 0) {
          toast.success(res.data.data)
        } else {
          toast.error(res.data.data)
        }
        this.loadingVisible = false
        refetchData()
      })
    }

    const VMICommit = function (id, status) {
      store.dispatch('purchaseorder/changeStatus', {id: id, status: status}).then(res => {
        if (res.data.code == 0) {
          toast.success(res.data.data)
        } else {
          toast.error(res.data.data)
        }
        this.refetchData()
      })
    }

    const toEdit = function(param) {
      axios.post('/api/purchaseorder/isInAuditing',{id:param}).then(res =>{
        if(res.data.code == 0){
          this.$router.push({ name: 'apps-purchaseorder-edit', query: { id: param },params:{readonly:0} })
        }else {
          toast.error(res.data.data)
        }
      })
    }

    const saveCloseRemark = function () {
      store.dispatch('purchaseclose/create', {orderId: this.selectedItem.order_id,remark:this.closeRemarkInfo}).then(res => {
        if (res.data.code == 0) {
          toast.success(res.data.data)
        } else {
          toast.error(res.data.data)
        }
        this.closeRemarkInfo = ''
        refetchData()
      })
    }

    return {
      ...toRefs(state),
      ...methods,


      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      /* searchKeyNameColumn,*/
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      getCodeRemark,
      saveRemark,
      saveRemarkPurchase,
      changeSelect,
      // page

      commit,
      submitMoney,
      changepayMoney,
      oaMenu,
      changeState,
      saveCreator,
      changeOrderId,
      del,
      orderOver,
      createStockUp,
      VMICommit,
      toEdit,
      saveCloseRemark,
    }
  },
  created() {
    const userData = getUserData()
    this.allPurchaseUser = getCodeOptions('purchaseUser')
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
